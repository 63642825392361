import Vue from 'vue';
import store, { Ride } from '../../store';
import { debounce } from 'lodash-es';
import Close from '../../components/Close.vue';
import Fuse from 'fuse.js';
import { getAnalytics, logEvent } from "firebase/analytics";

const updateSearchTerm = debounce((term: string) => {
  store.dispatch(`updateSearchTerm`, term);
}, 250);

const fuse_options = {
  includeScore: true,
  keys: [
    `name`,
  ]
};

export default Vue.extend({
  computed: {
    current_rides(): Ride[] {
      switch (store.state.display_land) {
        case `dl`:
          return store.getters.disneyland_rides;
        
        case `dca`:
          return store.getters.california_adventure_rides;
      
        default:
          return store.getters.combined_rides;
      }
    },
    selected_land_name(): string {
      switch (store.state.display_land) {
        case `dl`:
          return `Disneyland`;
        
        case `dca`:
          return `California Adventure`;
      
        default:
          return `All`;
      }
    },
    fuse(): Fuse<unknown> {
      const fuse = new Fuse(this.current_rides, fuse_options);
      return fuse;
    },
    search_term(): string {
      return store.state.search_term;
    },
    search_results(): Ride[] {
      const results = this.fuse.search(store.state.search_term)
        .filter((result) => {
          return result.score && result.score < 0.6;
        })
        .map((result) => {
          return result.item as Ride;
        });
      
      return results;
    }
  },
  watch: {
    search_term(): void {
      const analytics = getAnalytics();
      logEvent(analytics, `search`, {
        search_term: this.search_term,
      });
    },
    search_results(): void {
      const analytics = getAnalytics();
      logEvent(analytics, `view_search_results`, {
        search_term: this.search_term,
      });
    }
  },
  methods: {
    handleSearchValueChange(e: InputEvent): void {
      const val = (e.target as HTMLInputElement).value;
      updateSearchTerm(val);
    },
    clearSearchTerm(): void {
      const input = this.$refs?.search as HTMLInputElement;
      input.value = ``;
      store.dispatch(`updateSearchTerm`, ``);
      input.focus();
    }
  },
  components: { Close }
});